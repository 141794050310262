.SiteView {

  margin: 0px;
  height: 100%;
  display: flex;
  flex-flow: column;

  .site-page-header-row {

    flex: 0 1 auto;
    width: 100%;
    display: inline-flex; // magic!!
    flex-flow: row;

    .site-page-header {
      padding: 0px;
      flex: 1 1 auto;
    } // .site-page-header

    .audit-button {
      flex: 0 1 auto;
    }

  } // .site-page-header-row

  .SiteViewTabs {

    flex: 1 1 auto;
    flex-flow: column;

    .ant-tabs-nav {
      flex: 0 1 auto;

      $tab-outline-color: #ddd;
      $tab-border-radius: 8px;
      $tab-content-background-color: #f0f2f5;
      $tab-unselected-background-color: #e4e4e4;


      &:before {
        border-bottom: 1px solid $tab-outline-color; 
      }

      .ant-tabs-tab {
        border-left-color: $tab-outline-color;
        border-top-color: $tab-outline-color;
        border-right-color: $tab-outline-color;
        border-top-left-radius: $tab-border-radius;
        border-top-right-radius: $tab-border-radius;

        border-bottom-color: $tab-outline-color;

        background-color: $tab-unselected-background-color;

        .review-notification {
          margin-right: 0px;
          margin-left: 6px;
        }
      }

      .ant-tabs-tab-active {
        border-bottom-color: $tab-content-background-color;
        background-color: $tab-content-background-color;
      }

    }

    .ant-tabs-content-holder {
      flex: 1 1 auto;

      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }

    } // .ant-tabs-content-holder

    .SiteDocumentReviewList-SiteFrame {
      height: 100%;
    }

  } // .SiteViewTabs
} // .SiteView
