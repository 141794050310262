.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.alert {
  margin: 20px auto 0px;
  max-width: 520px;
  text-align: center
}

