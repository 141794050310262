.DocumentEditSteps {

  li.ant-pagination-item.ant-pagination-item {
    display: none;
  }

  .ant-steps-item-icon, .ant-steps-item-content {
    margin-right: 25px;
  }

  height: 100%;
  display: flex;
  flex-flow: column;
    
  .ant-steps {
     margin: 6px;
     flex: 0 1 auto;
   }
    
   .steps-content {

      flex: 1 1 auto;
      background-color: #fafafa;

      h2 {
        font-size: large !important;
        padding: 6px;
      }

      .VisitSelector,
      .FormSelector,
      .DocumentRedactionEditor,
      .DocumentUploadPage {

        height: 65vh;
      
        h3 {
          margin-left: 6px;
        }

        .ant-transfer {

          height: 100%;
          margin-left: 100px;

          .ant-transfer-list {
            background-color: #fff;
          }
        } // .ant-transfer

      } // .VisitSelector, .FormSelector,
      // .DocumentRedactionEditor, .DocumentUploadPage
    }
    
    .steps-action {
      flex: 0 1 auto;
      button {
        margin-top: 10px;
      }
    }
}
