.Header {
  padding: 0;

  &.ant-layout-header {
    padding: 0; // ant-layout-header is usually '0 50px'
  }

  width: 100%;

  .navigation {
    display: flex;
    flex-flow: row nowrap;
    background-color: #f0f2f5;
    width: 100%;


    .logo {

      //background-color: white;

      float: left;

      margin: 0px;
      margin-top: 4px;
      //border: solid 1px #d0d2d5;

      a {

        margin-top: 4px;
        padding: 18px;
        padding-right: 35px;
        padding-left: 0px;
        margin-left: -8px;

        img.HeaderLogo {

          height: 50px;

        }

      }

    } // .logo

    .ant-menu {
      justify-content: flex-end!important;
      flex-basis: auto;
      display: flex !important;
      width: 100%;
      background-color: #f0f2f5;

    }

  } // .navigation

}
