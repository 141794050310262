.DocumentListVersions {
  .ant-table-cell {
    a {

      display: flex;
      width: 100%;
      padding-left: 2ex;

      &:hover {
        background-color: #abd;
        color: white;
      }
    }
  }

}
