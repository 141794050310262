.Layout {

  border: 0px;
  padding: 0px 25px;

  .Header {
    width: 100%;
    padding: 0px;
    margin: 0px;
    border: 0px;

  }

  & > main {

    margin: 0px;
    padding: 0px;
    border: 0px;
    width: 100%;

  }

  .Footer {
    width: 100%;
    border: 0px;
    padding: 0px;
    margin: 10px 0px;
    text-align: center;

  }

}

.ViewportLayout {

  height: 100vh;
  display: flex;
  flex-flow: column;

  & > .Header {
    flex: 0 1 auto;
  }

  & > .LayoutContent {
    flex: 1 1 auto;
    overflow: hidden;

    display: flex;
    flex-flow: column;

    & > .Breadcrumb {
      flex: 0 1 auto;
    }

    & > .SiteLayout-Main,
    & > .StudiesLayout-Main,
    & > .DocumentLayout-Main
    {
      flex: 1 1 auto;
      overflow: hidden;
    }
  }

  & > .Footer {
    flex: 0 1 auto;
  }

}

