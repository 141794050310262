.EditDocumentTask {

  .steps-content {
    height: 0px; // still don't know why this works

    .CertificationPage {
      height: 100%;

      div.DocumentItemView {
        height: 100%;

        .ant-layout {
          height: 100%;
        }

      }
    }

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

      }
    } // .ant-spin-nested-loading
  }

}
