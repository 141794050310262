.SubjectDocumentList {
  height: 100%;

  .SubjectFrame {
    height: 100%;

    .site-frame-content {

      height: 100%;

      display: flex;
      flex-flow: column;

      .page-header {
        flex: 0 1 auto;
        padding: 0px;
      }

      .ListDocumentsTask {

        flex: 1 1 auto;
        overflow: hidden;
      }

      .document-upload {

        flex: 0 1 auto;

        .ant-upload-drag {

          .icon-container {

            .anticon {
              color: #40a9ff;
              font-size: 48px;
            }
          }
        }

      }


    }
  } // SubjectFrame
} //.SubjectDocumentList
