.DocumentViewFrame {

  height: 100%;
  overflow: hidden;

  .site-frame-content {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;
      & > div {
        height: 100%;

        .content-loading-feedback {
          height: 100%;
          max-height: 100%;

        }


      }
      .ant-spin-container {
        height: 100%;

        .DocumentViewContent {
          height: 100%;
        }
      }
    }
  }
}
