.TrainingLayout {

  border: 0px;
  padding: 0px 25px;
  height: 100vh;


  .Training {
    height: 100%;

    .content-header {
      background-color: inherit;
    }

    .article {
      height: 100%;
      overflow: auto;
      background-color: white;
      border: solid black 1px;
      padding: 1em;

      blockquote {
        color: #666;
        margin: 0;
        padding-left: 2em;
        border-left: 0.5em #eee solid;
      }

      img {
        max-width: 90vw;
      }
    } // .article

    .Footer {

      width: 100%;
      display: flex;
      flex-flow: row;

      .label {
        flex: 1 1 auto;
        text-align: left;
      }

      .next-button {
        flex: 0 1 auto;
      }

    }

  }
}
