.ListDocumentsTask {

  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;

      display: flex;
      flex-flow: column;

      .ant-table {
         height: 100%;
         overflow-y: auto;


         &.ant-table-empty {

           .ant-table-container {
             height: 100%;
             .ant-table-content {
               height: 100%;
               & > table {
                 height: 100%;
               }
             }
           }

         } // .ant-table-empty

        .ant-table-container {
          height: 100%;

          thead, th {
            position: sticky;
            top:0;
            z-index:2;
          }

          td.version {
            padding:2px;
            border-spacing: 0px;
          }

          .ant-table-expanded-row {

              & > .ant-table-cell {
              background-color: #e8e8e8;

              .DocumentList {

                #versionsTable {

                  margin: 5px;
                  border: solid 1px #a8a8a8;

                }

                ul.ant-pagination {
                  display: none;
                }

              } // .DocumentList
            } // .ant-table-cell

          } // .ant-table-expanded-row

        } // .ant-table-container
      } // .ant-table

      .ant-table-pagination {
        flex: 0 1 auto;
        margin: 8px 24px;
      }

    } // .ant-spin-container
  } // .ant-spin-nested-loading


}
