.Study {

  height: 100%;


  .content {

    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;

    .study-page-header-row {

      flex: 0 1 auto;

      width: 100%;
      display: inline-flex; // magic!!
      flex-flow: row;


      .study-page-header {
        padding: 0px;
        flex: 1 1 auto;
      }

      .audit-button {
        flex: 0 1 auto;
      }

    } //.study-page-header-row

    .site-list-table {
      flex: 1 1 auto;
      overflow: hidden;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;

          display: flex;
          flex-flow: column;

          .ant-table {
             height: 100%;
             overflow-y: auto;

             thead, th {
               position: sticky;
               top:0;
               z-index:2;
             }

            .ant-table-container {
              height: 100%;
            } // .ant-table-container
          } // .ant-table

          .ant-table-pagination {
            flex: 0 1 auto;
            margin: 8px 24px;
          }

        } // .ant-spin-container
      } // .ant-spin-nested-loading
    } // .site-list-table
  } // .content
} //.Study


// dropdown is outside the main part of the DOM
.site-name-filter-dropdown {
  padding: 8px;
}
