
.Register {
  padding: 60px 0;
}

.Register form {
  margin: 0 auto;
  max-width: 420px;
}

