.SubjectDocumentAdd {

  height: 100%;
  width: 100%;

  .site-frame-content {

    height: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .page-header {
      flex: 0 1 auto;

      padding: 0px;
    }

    .local-file-notice {
      flex: 0 1 auto;
    }

    .NewDocumentTask {
      flex: 1 1 auto;
    }
  }

  .spin-holder {
    width: 100%;
    height: 100%;
    
    padding-top: 200px;
    text-align: center;
    .spin {
      margin: auto;
    }

  }

}
