.SubjectDocumentEdit {

  height: 100%;
  width: 100%;

  &.spin-holder {

    .ant-spin {
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .site-frame-content {
    height: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .page-header {
      flex: 0 1 auto;

      padding: 0px;
      margin: 0px;
    }

    .EditDocumentTask {
      flex: 1 1 auto;
    }

    &.spin-holder {

    }

    .ant-spin {
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

}
