.DocumentRedactionEditor {

  height: 100%;

  .controls {

    padding-left: 20px;
    margin-left: 0;

    &>.ant-page-header-content {

      padding-top: 0;

      .ant-space {

        &.controls-left {

        }

        &.controls-right {
          float: right;
        }

        .ant-space-item {
        }

      }

      .ant-divider {
        margin: 0 auto;
      }


    }
  }

  .document {
    height: 100%;
  }

}
