.UserProfile .content {
  padding: 10px 0;
  /*
  border: solid 1px red;
  */
}

.UserProfile .content h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.UserProfile .content p {
  color: #999;
}
