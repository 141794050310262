.AdminStudyForm, .DatakeyArnListItem {

  .dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .dynamic-delete-button:hover {
    color: #777;
  }

  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.AdminStudyForm {
    input#subjectPath,
    input#usersPath {
      font-family: monospace;
    }

}
