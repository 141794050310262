.SiteSubjectList {

  height: 100%;

  
  .subject-table {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        display: flex;
        flex-flow: column;

        .ant-table {
           height: 100%;
           overflow-y: auto;

           thead, th {
             position: sticky;
             top:0;
             z-index:2;
           }

          .ant-table-container {
            height: 100%;
          } // .ant-table-container
        } // .ant-table

        .ant-table-pagination {
          flex: 0 1 auto;
          margin: 8px 24px;
        }

      } // .ant-spin-container
    } // .ant-spin-nested-loading


  } // .subject-table
}
