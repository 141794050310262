.DocumentItemView {

  height: 100%;

  .ant-layout-header {

    display: inline-flex;
    flex-flow: row;

    background-color: transparent;
    padding: 0 0;

    & > .page-title {
      flex: 0 1 auto;
      left: -20px;
    }

    & > .summary {
      flex: 1 1 auto;

      .ant-tag {
        line-height: 28px;
        font-size: 14px;
      }
    }

    & > .document-item-header-toolbar {
      flex: 0 1 auto;
      margin: auto 0;
    }
  }


  .metadata {

    overflow: auto;

    .metadata-toolbar {
      width: 100%;
      text-align: right;
    }

    .ant-card-head-title {
      font-weight: bold;
    }

    .ant-descriptions-item-label {
      font-weight: bold;
      color: rgba(50, 50, 50, 0.8);
    }
  }

  /*

  somehow the PSPDFKit-Container class appears twice in the DOM hierarchy

  .PSPDFKit-Container { // vendor pdf outermost div
    //width: 300px;
  }

  */
  .DocumentStatusIconBar {

    $toolbar-height: 44px;
    $toolbar-width: 140px;

    width: 100%;
    position: relative;
    top: -$toolbar-height;

    & > .container {

      float: right;
      right: 0px;
      width: $toolbar-width;
      height: $toolbar-height;

      .anticon {
        height: $toolbar-height;
        svg {
          height: $toolbar-height;
        }
      }
    }
;
  }

}

