.DocumentReview {
  height: 100%;

  .site-frame-content {
    height: 100%;

    .ant-spin {
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-spin-nested-loading{
      height: 100%;
      .ant-spin-container {
        height: 100%;
        overflow: hidden;

        display: flex;
        flex-flow: column;

        h1 {
          flex: 0 1 auto;
        }

        .summary {
          flex: 1 1 auto;
          overflow: hidden;
        }
      }
    }
  }

  .RejectPanelForm {

    //background-color: #aaf;
      width: 100%;
      height: 100%;
      padding: 24px;

      .ant-checkbox-group {

        padding-bottom: 24px;

        p {
          margin: 0;

          .ant-checkbox-wrapper {
            min-width: 12em;
          }
        }
      }


  }
}

.ant-modal-root {

  .SignaturePanelChooser {
     margin-bottom: 50px;
  }

}

.ant-popover {
  width: 800px;
}
